import { LangSelectFooter } from "./LangSelect";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return(
        <div className="footer">
            <div className="footerContent">
                <p>{ t('copyright') } © Iggi.lv {new Date().getFullYear()}</p>
                <LangSelectFooter />
            </div>
        </div>
    )
}

export default Footer;